import React, { useState } from 'react';
import { useNavigate, useLocation } from "react-router-dom";

const Navbar = () => {

    const items = [
        {
            name: "Home",
            path: "/"
        },
        {
            name: "Feature",
            path: "feature"
        },
        {
            name: "Contact Us",
            path: "contact"
        }
    ];

    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [sideBar, setSideBar] = useState(false);

    return (
        <div>
            <div className='hidden lg:block'>
                <div className='shadow-md py-4'>
                    <div className='container px-4 sm:px-8 md:px-14 mx-auto'>
                        <div className='flex justify-between items-center'>
                            <img
                                src="./images/lincsme_logo-002.svg"
                                alt="logo"
                                onClick={() => navigate("/")}
                                className='max-h-20 object-contain hover:cursor-pointer'
                            />
                            <div className='flex justify-between items-center flex-wrap'>
                                <div className='flex items-center gap-6 xl:gap-10 text-primary-gray'>
                                    {items?.map((res, i) => (
                                        <p
                                            key={i}
                                            onClick={() => {
                                                if (res?.path === "/") {
                                                    navigate("/");
                                                } else {
                                                    navigate("/", {
                                                        state: {
                                                            pathname: res.path
                                                        }
                                                    });
                                                }
                                            }}
                                            className={`cursor-pointer opacity-70 hover:text-primary-blue font-medium ${pathname === res?.path ? 'gradient-text' : ''}`}
                                        >
                                            {res?.name}
                                        </p>
                                    ))}
                                </div>
                            </div>
                            <div className='flex items-center gap-3 xl:gap-5'>
                                <div className='font-bold gradient-border cursor-pointer'>
                                    Sign In
                                </div>
                                <div
                                    className='bg-custom-gradient rounded-lg font-bold py-3 px-6 cursor-pointer'
                                    onClick={() => window.open("https://play.google.com/store/apps/details?id=com.intellolinks.lincsme", "_blank")}
                                >
                                    Download Lincsme
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='block lg:hidden'>
                <div className='w-full shadow-md'>
                    <div className='container px-4 sm:px-8 md:px-14 mx-auto'>
                        <div className='flex justify-between items-center'>
                            <img
                                src="./images/lincsme_logo-002.svg"
                                alt="logo"
                                onClick={() => navigate("/")}
                                className='max-h-16 object-contain hover:cursor-pointer'
                            />
                            <div className='py-4'>
                                <div className='cursor-pointer' onClick={() => setSideBar(!sideBar)}>
                                    <svg height={30} width={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M5 6H12H19M5 12H19M5 18H19" stroke="#143f72" strokeWidth="2" strokeLinecap="round"></path> </g></svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {sideBar &&
                <div className="absolute bg-white z-50 w-full">
                    <aside className={`${sideBar ? "w-full p-4" : "w-0"} h-full overflow-y-auto scrollbar-hide transition-all shadow-xl`}>
                        <div className="w-full flex justify-center items-center flex-col gap-4 mt-3 mb-4">
                            {items?.map((res, i) => (
                                <p
                                    key={i}
                                    onClick={() => {
                                        if (res?.path === "/") {
                                            navigate("/");
                                        } else {
                                            navigate("/", {
                                                state: {
                                                    pathname: res.path
                                                }
                                            });
                                        }
                                        setSideBar(false);
                                    }}
                                    className={`cursor-pointer opacity-70 hover:text-primary-blue font-medium ${pathname === res?.path ? 'gradient-text' : ''}`}
                                >
                                    {res?.name}
                                </p>
                            ))}
                        </div>
                        <div className='flex justify-center items-center flex-col gap-4'>
                            <div className='font-bold gradient-border cursor-pointer'>
                                Sign In
                            </div>
                            <div
                                className='bg-custom-gradient rounded-lg font-bold py-3 px-6 cursor-pointer'
                                onClick={() => window.open("https://play.google.com/store/apps/details?id=com.intellolinks.lincsme", "_blank")}
                            >
                                Download Lincsme
                            </div>
                        </div>
                    </aside>
                </div>
            }
        </div>
    )
}

export default Navbar;
